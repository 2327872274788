.cardSenha {
  align-items: center;
  user-select: none;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 45px;
  width: 110px;
  justify-content: center;
  margin-right: 5px;
  padding-inline: 6px;
  padding-block: 3px;
  position: absolute;
  box-shadow: 0 4px 20px 2px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%) !important;
  z-index: 15000;
}
.label {
  font-size: 11px;
  margin: 0;
  font-weight: 800;
  color: red;
  text-align: start;
}
.senha {
  font-size: 14px;
}
