.btn-laterais {
  background: #ebebeb;
  color: #0d6efd;
  border: none;
  border-radius: 10px 0 0 10px;
  box-shadow: 3px 3px 7px rgb(0 0 0 / 10%);
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  outline: none;
  position: fixed;
  right: 0;
  text-align: center;
  /* top: 60px; */
  transition: 0.5s right ease, 0.5s box-shadow ease;
  width: 30px;
  z-index: 1001;
}

.btn-laterais.primeiro {
  top: 60px;
}

.btn-laterais.segundo {
  top: 100px;
}

.btn-laterais.terceiro {
  top: 140px;
}

.btn-laterais-open {
  right: 349px;
  box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
}

.btn-laterais::before {
  background: red;
  border-radius: 50%;
  content: '';
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  height: 16px;
  line-height: 16px;
  position: absolute;
  right: 20px;
  width: 16px;
  opacity: 0;
  transform: scale(0.5);
  transition: 0.15s opacity ease, 0.15s transform ease;
}

.btn-laterais.qty::before {
  content: attr(data-qty);
  opacity: 1;
  transform: scale(1);
}

.area {
  background: #ebebeb;
  box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
  width: 350px;
  height: 300px;
  padding: 20px;
  position: fixed;
  right: -350px;
  height: 100%;
  top: 0;
  overflow: auto;
  transition: 0.5s right ease, 0.5s box-shadow ease;
  z-index: 1000;
}

.area-open {
  right: 0;
  box-shadow: -3px -3px 10px rgba(0, 0, 0, 0.3);
}

.btn-refresh {
  position: absolute;
  z-index: 2000;
  left: 20px;
  top: 10px;
  font-size: 15px;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
}
