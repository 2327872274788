.card-cliente-container{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-cliente-container span {
    font-size: 12px;
    font-weight: 400;
}

.card-cliente-container i {
    font-size: 16px;
}