.btnCopy {
  border: none;
  background-color: transparent;
  color: #0d6efd;
}

.btnCopied {
  border: none;
  background-color: transparent;
  color: #28a745;
}
