@keyframes cardEntrance {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(50px);
  }
}
.alerta-card {
  background-color: white;
  border-radius: 4px;
  width: 275px;
  height: auto;
  display: grid;
  grid-template-columns: 35px 1fr;
  margin-bottom: 8px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.075), 0 1px 2px rgba(0, 0, 0, 0.075), 1px 2px 4px rgba(0, 0, 0, 0.075),
    1px 3px 8px rgba(0, 0, 0, 0.075), 2px 4px 16px rgba(0, 0, 0, 0.075);
  padding-top: 10px;
  padding-bottom: 10px;
  animation: cardEntrance 0.5s ease-in-out;
  z-index: 10001;
  position: relative;
}

.alerta-card-fechar {
  animation: fadeOut 0.5s ease-in-out;
}

.close-button {
  position: absolute;
  top: -9px;
  right: -5px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #000;
  cursor: pointer;
}

.close-button:hover {
  color: #ff0000;
}

.alerta-texto {
  margin-left: 5px;
  margin-right: 5px;
  text-align: justify;
}
