.shadow-none textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
    outline: 0px !important;
    /* -webkit-appearance: none; */
    box-shadow: none !important;
}

.uso-modal {

}

.uso-modal select {
    width: 100%;
    border: 0px;
    background-color: #fff;
    height: 42px;
    cursor: pointer;
}