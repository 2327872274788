.select-situacao {
  border: none;
  border-radius: 5px;
  text-align: center;
  width: 100px;
  margin: 0 5px 0 5px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid lightgray;
}

.select-situacao > option {
  background-color: white !important;
  color: black !important;
}
