.container {
  max-height: 450px;
  overflow-y: scroll;
  max-width: 100%;
}

.divlegenda {
  height: 15px;
  width: 50px;
  background-color: var(--backgroundColor, #f8d7da);
  margin-right: 5px;
}
