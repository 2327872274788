.titulo {
    font-weight: 600;
    position: relative;
}

.titulo::before {
    content: '';
    position: absolute;
    height: 4px;
    width: 5vh;
    bottom: -3px;
    left: 0;
    border-radius: 8px;
    background: #0D6EFD;
    /* background: linear-gradient(45deg, #8e2de2, #4a00e0); */
}