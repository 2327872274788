.Container {
  min-height: 400px;
  max-height: 800px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.btnRemoverItem {
  border: none;
  font-size: 20px;
  color: red;
  font-weight: 900;
  padding: 0;
  margin: 0;
  background-color: transparent;
}
.btnDownloadItem {
  border: none;
  font-size: 20px;
  color: blue;
  font-weight: 900;
  padding: 0;
  margin: 0;
  margin-left: 5px;
  background-color: transparent;
}
