.card-usuario-container-expediente {
  background-color: #f9f2ed;
  list-style: none;
  border-radius: 5px;
  margin: 5px 0;
  padding: 12px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  position: relative;
  transition: all 0.3s;
}

.card-usuario-container-expediente:hover {
  opacity: 0.7;
  transform: translateX(10px);
}

.card-usuario-container-expediente span {
  font-size: 14px;
  font-weight: 400;
}
