.container-horarios {
  margin: 15px 0 15px 0;
  display: flex;
  flex-direction: column;
}

.btn-ajuste-horario {
  margin-left: 5px;
  border: none;
  background: transparent;
}
