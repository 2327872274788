.notification-div {
  background-color: white;
  padding-inline: 15px;
  border-radius: 5px;
  font-size: 12px;
  padding-block: 5px;
  position: relative;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.075), 0 1px 2px rgba(0, 0, 0, 0.075), 1px 2px 4px rgba(0, 0, 0, 0.075),
    1px 3px 8px rgba(0, 0, 0, 0.075), 2px 4px 16px rgba(0, 0, 0, 0.075);
  margin-bottom: 10px;
  animation: cardEntrance 0.5s ease-in-out;
}

.notification-div-close {
  animation: fadeOut 0.5s ease-in-out;
}

.read-button {
  border: none;
  position: absolute;
  top: 0;
  right: 12px;

  background-color: transparent;
}

@keyframes cardEntrance {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(50px);
  }
}

.go-button {
  border: none;
  position: absolute;
  top: 0;
  right: -3px;
  background-color: transparent;
}
