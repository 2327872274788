.tableHeader {
  cursor: pointer;
}

.collapse {
  height: 0px;
}

.expand {
  max-height: 1000px;
  animation: 0.3s ease-in-out;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.308);
  border-radius: 10px;
  overflow: hidden;
}

.bgLiberacoes {
  background-color: #1f3fcc6e;
}

.bgReceber {
  background-color: #e95e5e;
}

.maxHeight {
  max-height: 500px;
  overflow-y: scroll;
}

.inputDiasLiberar {
  width: 80px;
  height: 25px;
}
