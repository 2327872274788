.item-atendimento-card {
  display: grid;
  grid-template-columns: auto 25px;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 420px;
  max-height: 130px;
  background-color: rgb(255, 255, 255);
  position: relative;
  overflow: hidden;
  transition: max-height 0.25s ease-out;
  box-shadow: 0 4px 20px 2px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%) !important;
  line-height: 2;
  padding: 0;
  box-sizing: border-box;
}

.slim {
  width: 100%;
  max-height: 90px;
}

.cardOpen {
  max-height: 100%;
  transition: max-height 0.35s ease-in;
  padding-bottom: 20px;
}

.icone-alteracao {
  color: orangered;
}

.icone-erro {
  color: crimson;
}

.item-atendimento-card a {
  cursor: pointer;
  text-decoration: none;
  color: rgb(100, 100, 100);
}

.item-atendimento-card:hover {
  background-color: #f9f2ed;
  /*#FFD43B;*/
}

.item-atendimento-card:hover .linha-botao {
  background-color: #f9f2ed;
  /*#FFD43B;*/
}

.informacoes-card {
  margin: 8px 0;
  padding-left: 13px;
  padding-right: 8px;
  font-size: 13px;
  font-weight: 500;
  box-sizing: border-box;
  width: 395px;
}

.informacoes-card-slim {
  margin: 8px 0;
  padding-left: 13px;
  padding-right: 8px;
  font-size: 13px;
  font-weight: 500;
  box-sizing: border-box;
}

.linha-cliente {
  white-space: nowrap;
  overflow: hidden;
}

.btn-infos-acessos {
  padding: 0;
  border: 0;
  margin-left: 5px;
  background: transparent;
}

.ocorrencia {
  text-align: justify;
  width: 400px;
  /* overflow: hidden; */
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.ocorrencia-slim {
  text-align: justify;

  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.linha-botao {
  height: 25px;
  padding: 3px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: rgb(255, 255, 255);
}

.div-button-collapse {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-collapse {
  width: 200px;
  height: 6px;
  border-radius: 5px;
  border: none;
  background-color: gray;
  transition: all ease 0.5s;
}

.button-collapse:hover {
  transform: scale(1.1);
}

.div-button {
  border-radius: 0 10px 10px 0;
  background-color: #4698ff;
  /* background-color: #2c85ff; */
  /* background-color: #0d6efd; */
  padding: 0;
  position: absolute;
  right: 0px;
  height: 100%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.btn-edicao-atendimento {
  background-color: transparent;
  border: none;
  font-size: 16px;
  height: 90%;
  transition: all ease 1s;
}

.btn-edicao-atendimento:hover {
  transition: all 0.3s ease;
  transform: scale(1.2) translateX(-1px);
}

@media (max-width: 900px) {
  .item-atendimento-card {
    width: 660px;
    max-height: 160px;
  }

  .cardOpen {
    max-height: 100% !important;
    transition: max-height 0.35s ease-in;
  }

  .informacoes-card {
    box-sizing: border-box;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .item-atendimento-card {
    max-height: 130px;
  }

  .cardOpen {
    max-height: 100% !important;
    transition: max-height 0.35s ease-in;
  }
}

@media (min-width: 385px) and (max-width: 767px) {
  .item-atendimento-card {
    max-height: 135px;
    width: 100%;
  }
}
@media (max-width: 385px) {
  .item-atendimento-card {
    width: 100vw;
    max-height: 140px;
  }
}

@media (max-width: 600px) {
  .item-atendimento-card {
    position: sticky;
    margin-left: 0px;
  }

  .cardOpen {
    max-height: 100% !important;
    transition: max-height 0.35s ease-in;
  }

  .informacoes-card {
    box-sizing: border-box;
    width: 83vw;
  }

  .linha-cliente {
    white-space: nowrap;
    overflow: hidden;
  }

  .data {
    font-size: 10px;
    font-weight: 800;
    padding: 0;
    padding-left: 12px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .nome-equipe {
    padding-right: 0;
  }
}

.row-1-card-slim {
  display: grid;
  grid-template-columns: 105px 6.5fr 11fr;
  width: 100%;
}

.row-2-card-slim {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.coluna {
  display: flex;
  justify-content: center;
}

.span-situacao {
  font-size: 11px;
  font-weight: 500;
}
