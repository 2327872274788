.timeline {
  display: flex;
  box-sizing: content-box;
}

.timeline-card-encaminhado {
  font-size: 14px;
  font-weight: bold;
}

.timeline-card-ocorrencia {
  font-size: 14px;
  color: rgb(62, 62, 62);
  margin-bottom: 5px;
  text-align: justify;
  padding-right: 5px;
}

.footer {
  width: 100%;
  font-size: 10px;
  color: rgb(0, 0, 0);
  position: absolute;
  bottom: 0;
  background-color: inherit;
  padding-right: 5px;
  height: 15px;
  display: flex;
  font-weight: 500;
  justify-content: flex-end;
}

.timeline-card-data {
  position: relative;
  right: 20px;
}

.linha {
  border: 1px solid #ccc;
  width: 0;
  position: relative;
  height: 100%;
  left: 10px;
  transition: max-height 0.5s ease;
}

.linha-primeiro {
  height: 50%;
  top: 50%;
}

.linha-ultimo {
  height: 50%;
}

.circulo {
  height: 18px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #ccc;
  background-color: #9b9b9b;
  z-index: 1001;
  position: relative;
  left: 0;
  margin: auto;
  transition: all 0.5s ease;
}

.circulo-ultimo {
  background-color: #0d6efd;
}

.circulo-cancelado {
  background-color: #f33a3a;
}

.timeline-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin: 5px 5px 10px 20px;
  padding: 5px 5px 5px 15px;
  box-sizing: border-box;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 10px;
  position: relative;
  left: -25px;
  box-shadow: 0 4px 20px 2px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%);
  overflow: hidden;
  padding-bottom: 10px;
  transition: max-height 0.5s ease-in;
}

.timeline-card-solucao {
  background-color: #ffdc68;
  word-break: break-all;
}
.timeline-card-cancelado {
  background-color: #f8ad8a;
  word-break: break-all;
}

.timeline-card p {
  margin-bottom: 0;
}
