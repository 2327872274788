.calendarioContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.calendarioMenu {
  border-right: 1px solid rgba(173, 173, 173, 0.718);
  background-color: #ffffff;
  padding: 0;
  padding-right: 5px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.calendarioMenu .nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.calendarioMenu .nav-item {
  margin-bottom: 20px;
}

.calendarioMenuButton {
  background-color: transparent;
  width: 100%;
  padding: 5px 15px;
  margin-bottom: 5px;
  border: none;
  color: #712cf9;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.calendarioMenuButton:hover {
  background-color: aliceblue;
  transform: translateX(5px);
}

.calendarioMenuButtonActive {
  color: white;
  background-color: rgb(0, 136, 255) !important;
  border-radius: 5px 0 0 5px;
  transform: translateX(5px);
}

.calendarioContent {
  flex: 1;
  background-color: #ffffff;
  padding-left: 10px;
}
