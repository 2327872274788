.container-alerta {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
