.textRazao {
  font-weight: 400;
  font-size: 16px;
}

.textFantasia {
  font-weight: 400;
  font-size: 14px;
}

.labelInput {
  font-weight: 400;
  font-size: 14px;
}

.row {
  margin: 0;
  height: 80px !important;
  background-color: #dbdbdb;
}

.noCode {
  background-color: rgba(255, 30, 0, 0.438);
  font-size: 12px;
}

.inactive {
  background-color: rgb(255, 255, 255);
  font-size: 12px;
  color: #ccccccaf;
}
