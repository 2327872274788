@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.area-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.loading-animation {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.loading-animation::after {
  content: '  ';
  display: block;
  width: 90px;
  height: 90px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #416dff;
  border-color: #416dff transparent #416dff transparent;
  animation: rotate 1.2s linear infinite;
}
