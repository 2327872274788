a {
  color: inherit;
}
.card-liberacoes-container {
  background-color: #f9f2ed;
  list-style: none;
  border-radius: 5px;
  margin: 5px 0;
  padding: 12px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
}

.card-liberacoes-container span {
  font-size: 15px;
  font-weight: 400;
}

.tag-card-liberacoes-ativo {
  width: 15px;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #4e41f0;
  position: absolute;
  left: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.tag-card-liberacoes-inativo {
  width: 15px;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #e44c4e;
  position: absolute;
  left: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.primary {
  background-color: rgba(13, 109, 253);
}

.danger {
  background-color: rgba(217, 84, 79);
}

.info {
  background-color: rgba(108, 117, 125);
}

.success {
  background-color: rgba(40, 167, 69);
}
.card-financeiro {
  /* border: 1px solid gray; */
  border-radius: 8px;
  padding: 8px;
  text-align: start;
  width: 250px;
  box-shadow: 3px 5px 10px 1px rgba(0, 0, 0, 0.247);
  color: white;
}

.card-financeiro h6 {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.459);
  padding: 8px;
  border-radius: 8px;
}
