.container-form {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
}

@media (max-width: 770px) {
  .container-form {
    grid-template-columns: 1fr;
  }
}

.informacoes-atendimento {
  padding-right: 10px;
}

.btn-alertas::before {
  background: red;
  border-radius: 50%;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  height: 16px;
  line-height: 16px;
  width: 16px;
  position: absolute;
  top: -5px;
  left: -10px;
  transition: 0.15s opacity ease, 0.15s transform ease;
}

.btn-alertas.qty::before {
  content: attr(data-count);
  opacity: 1;
  transform: scale(1);
}

.div-timeline {
  /* height: 520px; */
  height: 490px;
  overflow-y: auto;
  overflow-x: hidden;
}

.btn-edicao {
  border-radius: 4px;
  height: 25px;
  width: max-content;
  border: none;
  color: white;
  font-size: 14px;
  background-color: #0d6efd;
}

.btn-edicao:hover {
  background-color: #e4e3e3;
  color: black;
}

.btn-disable {
  background-color: gray;
  opacity: 0.6;
  color: black;
}

.btn-disable:hover {
  background-color: gray;
  opacity: 0.6;
  color: black;
}

.grid-linha-titulo {
  display: grid;
  grid-template-columns: 55px 1fr 100px;
  align-items: center;
}

.btn-alertas {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
