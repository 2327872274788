.button {
  border: none;
  width: 35px;
  height: 35px;
  font-size: 35px;
  background-color: transparent;
}

.container {
  display: block;
  position: fixed;
  bottom: 15px;
  right: 15px;
}
