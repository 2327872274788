a { color: inherit; } 
.card-uso-container {
    background-color: #F9F2ED;
    list-style: none;
    border-radius: 5px;
    margin: 5px 0;
    padding: 12px 10px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all .3s;
}

.card-uso-container:hover {
    opacity: .7;
    transform: translateX(10px);
}