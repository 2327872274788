.infoContainer {
  max-height: 800px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.btnCopy {
  border: none;
  background-color: transparent;
}

.bodyCntReceber {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}