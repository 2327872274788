a { color: inherit; } 
.card-cliente-container {
    background-color: #F9F2ED;
    list-style: none;
    border-radius: 5px;
    margin: 5px 0;
    padding: 12px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: all .3s;
}

.card-cliente-container:hover {
    opacity: .7;
    transform: translateX(10px);
}

.card-cliente-container span {
    font-size: 14px;
    font-weight: 400;
}

.tag-card-cliente-ativo {
    width: 15px;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #4e41f0;
    position: absolute;
    left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.tag-card-cliente-inativo {
    width: 15px;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #e44c4e;
    position: absolute;
    left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.nowrap{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}