.notification-area {
  background: #ebebeb;

  width: 350px;
  height: 300px;
  padding: 20px;
  position: fixed;
  right: -350px;
  height: 100%;
  top: 0;
  overflow: auto;
  transition: 0.5s right ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.notification-area-open {
  right: 0;
}
