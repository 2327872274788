.closeButton {
  margin: 0;
  margin-bottom: 5px;
  height: 30px;
  padding: 0;
  padding-right: 15px;
  background-color: transparent;
  border: none;
  font-size: 30px;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
