.downloads-area {
  background: #ebebeb;

  width: 350px;
  height: 300px;
  padding: 20px;
  position: fixed;
  right: -350px;
  height: 100%;
  top: 0;
  overflow: auto;
  transition: 0.5s right ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.downloads-area-open {
  right: 0;
}

.download-card {
  background-color: white;
  padding-inline: 15px;
  border-radius: 5px;
  font-size: 14px;
  padding-block: 10px;
  position: relative;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.075), 0 1px 2px rgba(0, 0, 0, 0.075), 1px 2px 4px rgba(0, 0, 0, 0.075),
  1px 3px 8px rgba(0, 0, 0, 0.075), 2px 4px 16px rgba(0, 0, 0, 0.075);
  margin-bottom: 10px;
}

