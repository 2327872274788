.corpo {
  font-size: 12px;
}

.cabecalho {
  font-size: 13px;
}

.row:hover {
  background-color: rgb(46, 46, 46);
  transition: all 0.3s ease;
  color: white;
}

.inadimplente {
  background-color: rgba(255, 0, 0, 0.226);
}

.chaveExpirada {
  color: red;
  font-weight: 600;
}

.btnAcoes {
  border: none;
  background-color: transparent;
}

.row:hover .btnAcoes {
  color: white;
}

.datasParam {
  text-align: center;
  font-size: 11px;
  font-weight: 400;
}

.chaveDisponivel {
  background-color: green;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  width: fit-content;
  padding: 3px;
  font-size: 11px;
  border: 0;
}

.rectangle {
  height: 20px;
  width: 50px;
  background-color: #ffc5c5;
}

.modalNoFormat {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: bold;
}

.btnAlertaAlteracaoDtChave {
  margin: auto;
  padding: 0;
  background-color: transparent;
  border: none;
}
