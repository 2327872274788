.listagem-container {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
}

.listagem-itens {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.listagem-permissoes {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.btn-selecionar {
  border-bottom: 1px solid rgba(173, 173, 173, 0.718) !important;
  margin-bottom: 5px;
  background: transparent;
  border: none;
  width: 100%;
  padding-block: 0;
}

.btn-selecionar:hover {
  background-color: aliceblue;
}
.btn-selecionar.btn-selecionado {
  color: white;
  background-color: rgb(0, 136, 255) !important;
}

.btn-selecionar:hover .fa-solid {
  transition: all 0.3s ease;
  transform: scale(1.2) translateX(-1px);
}
