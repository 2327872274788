@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif !important;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none;
}

.form-select:focus {
  outline: none !important;
  box-shadow: none;
}

.form-select {
  padding: 0.375rem 1.25rem 0.375rem 0.75rem;
}

.mt-page {
  margin-top: 90px;
  margin-bottom: 90px;
}

.react-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.react-modal-content {
  width: 100%;
  max-width: 750px;
  max-height: 850px;
  background: #fff;
  padding: 1rem;
  position: relative;
  border-radius: 0.24rem;
  margin-left: 15px;
  margin-right: 15px;
}

.react-modal-content-vendedores {
  width: 100%;
  max-width: 1000px;
  max-height: 850px;
  background: #fff;
  padding: 1rem;
  position: relative;
  border-radius: 0.24rem;
  margin-left: 15px;
  margin-right: 15px;
}

.react-modal-content-uso {
  width: 100%;
  max-width: 800px;
  background: #fff;
  padding: 1rem;
  position: relative;
  border-radius: 0.24rem;
  margin-left: 15px;
  margin-right: 15px;
}

.react-modal-content-chave {
  width: 100%;
  max-width: 450px;
  max-height: 3000px;
  background: #fff;
  padding: 1rem;
  position: relative;
  border-radius: 0.24rem;
  margin-left: 15px;
  margin-right: 15px;
}

.react-modal-close {
  position: absolute;
  right: -20px;
  top: -15px;
  border: 0;
  background: transparent;
}
