.lista-container {
  list-style: none;
  border-style: solid;
  border-color: gray;
  border-top-width: 0;
  padding-top: 4px;
  padding-left: 0;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-left-width: 1px;
  position: absolute;
  background: white;
  z-index: 1001;
  width: -webkit-fill-available;
  cursor: pointer;
  height: auto;
  max-height: 400px;
  overflow-y: auto;
}

.item-lista {
  position: relative;
  padding-left: 5px;
  box-sizing: content-box;
  border-bottom: 1px solid gray;
}
.item-lista:hover {
  background-color: #0d6efd;
  color: white;
}

.selecionado {
  background-color: #0d6efd;
  color: white;
}
::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}
::-webkit-scrollbar {
  width: 6px;
  background: #f4f4f4;
}
::-webkit-scrollbar-thumb {
  background: #9a9a9a;
}
