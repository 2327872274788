.main-atendimentos {
  padding: 0px;
  margin: 0px;
  width: 100%;
  display: flex;
}

.main-atendimentos i {
  margin-right: 5px;
}

.btn-colapse {
  cursor: pointer;
  text-decoration: none;
  font-style: none;
}

.atendimentos {
  box-sizing: border-box;
  align-items: start;
  margin: auto;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
}

.sem-atendimento {
  display: flex !important;
  flex-direction: column;
}

.imagem {
  max-width: 200px;
  max-height: 200px;
}

@media (max-width: 1400px) {
  .atendimentos {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 992px) {
  .atendimentos {
    grid-template-columns: 1fr;
  }
}

.dropdown-filtro .dropdown button {
  display: block;
  width: 100%;
  border: 1px solid #ced4da;
  text-align: start;
}
.dropdown-filtro .dropdown button:hover {
  border: 1px solid #ced4da;
}

.dropdown-filtro .dropdown-toggle::after {
  position: absolute;
  right: 3%;
  top: 50%;
  overflow: hidden;
}


.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
          animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}