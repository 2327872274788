.btn-rounded {
  border-radius: 50%;
}

.obrigatorio {
  color: crimson;
  font-weight: 600;
}

.cep-cadastro-cliente {
  height: 57px;
}
.cnpj-cadastro-cliente {
  height: 57px;
}

.div-botoes > button {
  margin-left: 10px;
  margin-right: 10px;
}

.listar-aparelhos {
  height: auto;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.listar-aparelhos-collapse {
  box-shadow: inset 0 4px 20px 2px rgb(0 0 0 / 6%), inset 0 1px 4px rgb(0 0 0 / 8%) !important;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-left: 10px;
  padding-right: 10px;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.aparelhos {
  margin: 5px 5px 5px 5px;

  border: none;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px;
  color: white;
  background: darkcyan;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

@media (min-width: 280px) and (max-width: 380px) {
  .listar-aparelhos {
    grid-template-columns: 1fr 1fr;
  }
  .listar-aparelhos-collapse {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 381px) and (max-width: 780px) {
  .listar-aparelhos {
    grid-template-columns: repeat(3, 1fr);
  }
  .listar-aparelhos-collapse {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 781px) {
  .listar-aparelhos {
    grid-template-columns: repeat(4, 1fr);
  }
  .listar-aparelhos-collapse {
    grid-template-columns: repeat(4, 1fr);
  }
}

.div-alerta {
  padding: 2px;
  height: auto;
  max-height: 100px;
  /* white-space: nowrap; */
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  /* width: 250px; */
  text-align: center;
  justify-content: center;
}

.card-body .row {
  height: 100%;
}

.alerta {
  margin-top: 5px;
  margin-bottom: 5px;
}
