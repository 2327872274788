.wrapper {
  align-items: stretch;
  background: #222e3c;
  display: flex;
  width: 100%;
}
.content {
  direction: ltr;
  flex: 1;
  max-width: 100vw;
  padding: 0.75rem 1.5rem 0.75rem;
  width: 100vw;
  min-height: 70vh;
  margin-top: 0px !important;
}
.cabecalho {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.card-title {
  color: gray;
  font-size: 18px;
  text-align: center;
  margin-bottom: 5px;
}
.tab-content {
  max-width: 800px;
}
.card-editado {
  box-shadow: inset 0 4px 20px 2px rgb(0 0 0 / 6%), inset 0 1px 4px rgb(0 0 0 / 8%) !important;
}
