.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  /* position: absolute; */
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.infos {
  height: 100%;
  overflow: auto;
}
